<script setup>
 // Composables
 import { initializeLayout } from '@/composables/helpers/layouts';

 // Life cycle
 await initializeLayout();

 const { $bus } = useNuxtApp();
 useNuxtApp().hook('page:finish', () => {
  if (process.client) {
   const { hash } = useRoute();

   let topX = 0;
   if (hash) {
    topX = document.querySelector(hash)?.getBoundingClientRect()?.top;
   }
   if (topX === 0) {
    window.scrollTo({
     top: parseInt(topX),
     behavior: 'instant',
    });
   }

   $bus.emit('scroll-unlock');
  }
 });

 //  Add Chat Bot Script
 useHead({
  script: [
   {
    id: 'satisfiScript',
    src: 'https://chat.satis.fi/popup/embedder?popupId=18693',
    defer: true,
   },
  ],
 });

 onMounted(() => {
  window.history.scrollRestoration = 'manual';
 });
</script>
<template>
 <div>
  <NuxtLoadingIndicator />
  <MessBlocker />
  <div class="root-wrapper">
   <div class="layout-wrapper">
    <MessMetaActions />
    <GlobalHeader />
    <NuxtPage />
   </div>
  </div>
 </div>
</template>
